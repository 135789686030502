<template>
  <SidePopup
    :title="title"
    v-on:close="onClose"
    @submit="onSubmit"
    :loading="isLoading"
    routeback="/fornecedor/cadastro/setores-produto"
  >
    <h4 class="text-center">Dados básicos</h4>
    <LineDivider></LineDivider>
    <FormField
      label="Nome da Categoria"
      v-model="form.rotulo"
      :validation="$v.form.rotulo"
    />
    <FormField
      label="URL Amigável"
      v-model="form.slug"
      :validation="$v.form.slug"
    />
    <FormSwitch
      v-model="form.permite_alteracao_erp"
      label="Permitir que a integração desvincule produtos dessa categoria"
    />
    <FormSwitch
      v-if="usesDelegatedPriceStorage"
      v-model="form.promocional"
      label="Categoria promocional"
    />
    <small v-if="form.promocional">
      A categoria promocional se destina à exibir todos os produtos com política
      de desconto vigente de forma automática. Ela não pode ser vinculada
      manualmente aos produtos.
    </small>
    <LineDivider>
      <p class="font-weight-bold">Aplicar em:</p>
    </LineDivider>
    <FormCheckbox
      v-for="device in devices"
      :key="device.value"
      v-model="form.devices"
      :label="device.name"
      :val="device.value"
    />
    <FormCheckbox v-model="form.incluir_sitemap" label="Sitemap" />
    <FormCheckbox
      v-if="usesFacebookShopping"
      :disabled="form.quantfilhos != 0"
      v-model="form.incluir_facebook_shopping"
      label="Facebook Catálogos"
    />
    <small v-if="form.quantfilhos != 0"
      >Essa configuração só pode ser utilizada por uma categoria sem
      subcategorias.</small
    >
    <FormCheckbox
      v-if="usesGoogleShopping"
      :disabled="form.quantfilhos != 0"
      v-model="form.incluir_google_shopping"
      label="Google Shopping"
    />
    <small v-if="form.quantfilhos != 0"
      >Essa configuração só pode ser utilizada por uma categoria sem
      subcategorias.</small
    >
    <template v-if="mode == 'edit' || form.ids_setor_erp.length > 0">
      <LineDivider>
        <p class="font-weight-bold">IDs da Categoria no ERP:</p>
      </LineDivider>
      <p>Adicione IDs do ERP caso não hajam subcategorias associadas à esta.</p>
      <v-combobox
        v-model="form.ids_setor_erp"
        rounded
        outlined
        chips
        label="Após digitar um ID, aperte ENTER"
        multiple
      ></v-combobox>
    </template>

    <template v-slot:buttons>
      <FormButton text="Salvar" submit type="submissao" />
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import FormSwitch from '@/components/form/FormSwitch'
import FormCheckbox from '@/components/form/FormCheckbox'
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import {
  CATEGORIA_CREATE,
  CATEGORIA_UPDATE,
  CATEGORIA_LOAD
} from '@/store/actions/categoria'
import { SEGMENTO_LIST } from '@/store/actions/segmento'
import stringUtils from '../../../utils/stringUtils'

export default {
  name: 'CategoriaForm',
  components: {
    SidePopup,
    LineDivider,
    FormField,
    FormButton,
    FormSwitch,
    FormCheckbox
  },
  data: () => ({
    mode: 'add',
    isLoading: false,
    form: {
      devices: [],
      quantfilhos: 0,
      ids_setor_erp: [],
      slug: '',
      incluir_facebook_shopping: false,
      incluir_google_shopping: false,
      incluir_sitemap: false,
      promocional: false,
      permite_alteracao_erp: true
    },
    devices: [
      { value: 'desktop', name: 'Desktop' },
      { value: 'app', name: 'APP' }
    ],
    idsegmento: '',
    idsetorpai: ''
  }),
  validations: {
    form: {
      rotulo: { required }
    }
  },
  methods: {
    onClose(routeBack = false, reload = false) {
      this.isLoading = false
      if (routeBack) {
        this.$router.replace('/fornecedor/cadastro/setores-produto')
      }
      this.$emit('close', reload)
    },
    onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.normalizeSlug()
      this.isLoading = true
      let categoria = {
        ...this.form
      }
      categoria.descricao = this.form.rotulo
      categoria.idsegmento = this.form.idsegmento
      categoria.parent = this.idsetorpai
      if (this.mode == 'edit' && categoria.id) {
        categoria.idsetor = this.form.id
        this.$store
          .dispatch(CATEGORIA_UPDATE, categoria)
          .then(() => {
            this.$vueOnToast.pop('success', 'Categoria atualizada com sucesso')
            this.onClose(true, true)
          })
          .catch(() => {
            this.$vueOnToast.pop(
              'error',
              'Não foi possível atualizar a categoria'
            )
            this.isLoading = false
          })
      } else {
        categoria.idsegmento = this.idsegmento
        this.$store
          .dispatch(CATEGORIA_CREATE, categoria)
          .then(() => {
            this.$vueOnToast.pop('success', 'Categoria criada com sucesso')
            this.onClose(true, true)
          })
          .catch(() => {
            this.$vueOnToast.pop('error', 'Não foi possível criar a categoria')
            this.isLoading = false
          })
      }
    },
    getData(idsetor) {
      this.isLoading = true
      this.$store
        .dispatch(CATEGORIA_LOAD, idsetor)
        .then(data => {
          this.form = data
          this.form.quantfilhos = data.quantfilhos | 0
          this.form.devices =
            data.devices && data.devices != 0 ? data.devices : []
          this.form.ids_setor_erp =
            data.ids_setor_erp && data.ids_setor_erp != 0
              ? data.ids_setor_erp
              : []
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
          this.onClose()
        })
    },
    normalizeSlug() {
      this.form.slug = stringUtils.normalizeUrl(this.form.slug)
    },
    getSegmentos() {
      this.isLoading = true
      this.$store
        .dispatch(SEGMENTO_LIST)
        .then(data => {
          this.segmentos = data
          if (this.segmentos.length === 1) {
            this.idsegmento = this.segmentos[0].idsegmento
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    }
  },
  computed: {
    ...mapGetters([
      'getFornecedorId',
      'moduloFornecedorAtivo',
      'getFornecedorTag'
    ]),
    usesGoogleShopping() {
      return this.getFornecedorTag['uses-google-shopping']
    },
    usesFacebookShopping() {
      return this.getFornecedorTag['uses-facebook-shopping']
    },
    usesDelegatedPriceStorage() {
      return this.getFornecedorTag['uses-delegated-price-storage']
    },
    title() {
      return this.mode == 'edit' ? 'Editar categoria' : 'Nova categoria'
    }
  },
  created() {
    let { idsetor } = this.$route.params
    if (idsetor !== 'novo') {
      this.mode = 'edit'
      this.getData(idsetor)
    } else {
      this.$route.query.idsetorpai
        ? (this.idsetorpai = this.$route.query.idsetorpai)
        : ''
      this.getSegmentos()
    }
  },
  mounted() {},
  watch: {
    'form.slug': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.normalizeSlug()
        }
      }
    }
  }
}
</script>

<style lang="scss"></style>
